import './style.scss';
import { useSubscription } from '@stores/subscription';
import { usePlans } from '@stores/plans';
import { formatPrice, currencySymbol } from '@utils/price';
import { useUser } from '@stores/user';
import { useMemo, useEffect } from 'react';
import RestoreSubscriptionButton from './RestoreSubscriptionButton';
import { formatPaymentMethod } from '@utils/paymentMethod';
import { SubscriptionType } from 'src/types/subscription';
import formatInterval from '@utils/formatInterval';

function getYearDiffWithMonth(startDate: Date, endDate: Date) {
  const ms = endDate.getTime() - startDate.getTime();
  const date = new Date(ms);
  return Math.abs(date.getUTCFullYear() - 1970);
}

const formatPlanPrice = (subscription: SubscriptionType) => {
  const { plan, startsAt } = subscription;
  const introPrice = plan.prices.find((price) => price.type === 'intro');
  const standardPrice = plan.prices.find((price) => price.type === 'standard');

  // Montlhy multi-phase plans special case
  // show intro price up until they get 12 months of subscription
  const deltaYears = getYearDiffWithMonth(new Date(startsAt), new Date());
  if (introPrice && plan.metadata?.multiPhase === '12' && deltaYears < 1) {
    return `${currencySymbol(plan.currency || 'usd')}${formatPrice(introPrice.amount)}`;
  }

  if (standardPrice) {
    return `${currencySymbol(plan.currency || 'usd')}${formatPrice(standardPrice.amount)}`;
  }
};

const ConsumerStatus = (): JSX.Element => {
  const user = useUser((state) => state.user);
  const subscription = useSubscription((state) => state.subscription);
  const loadNextPlan = usePlans((state) => state.loadNextPlan);
  const nextPlan = usePlans((state) => state.nextPlan);
  const nextPriceId = usePlans((state) => state.nextPriceId);

  useEffect(() => {
    loadNextPlan();
  }, [loadNextPlan, subscription]);

  const isMigratedPianoUser = useMemo(() => {
    if (!user || !subscription) return false;
    // Migrated users from piano to stripe will have an paymentMethod in gefjon.
    if (user.userAccessType === 'piano' && subscription.paymentMethod?.attributes.card) return true;
  }, [user, subscription]);

  if (!subscription || !subscription.state) {
    return (
      <>
        <div className='subscription__status-container'>
          <div className='subscription__info-wrapper'>
            <div className='subscription__info-content'>
              <div className='subscription__type member'>No active subscription</div>
              <div className='subscription__renewal'>
                Become an Insider to unlock full access to our content and many more!
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const state = subscription.state.toLowerCase();
  const pm = formatPaymentMethod(subscription.paymentMethod);

  return (
    <>
      <div className='subscription__status-container'>
        <div className='subscription__info-wrapper'>
          {state === 'active' && (
            <>
              <div className='subscription__info-content'>
                <div className='subscription__subscriber-label'>Active</div>
                <div className='subscription__type member'>{subscription.cadence} subscription</div>
                <div className='subscription__renewal'>
                  Subscription
                  <span className='headline-bold'>
                    {' '}
                    auto-renews at {formatPlanPrice(subscription)}
                  </span>{' '}
                  on {subscription.endDate} {pm && <>with {pm.brand}</>}
                  {pm && pm.last4 && <> *{pm.last4}</>}
                </div>
              </div>
            </>
          )}
          {state === 'pending-plan-changed' && (
            <>
              <div className='subscription__info-content'>
                <div className='subscription__subscriber-label'>Active</div>
                <div className='subscription__type member'>{subscription.cadence} subscription</div>
                <div className='subscription__renewal'>
                  Your plan change to{' '}
                  <span className='headline-bold'>
                    {formatInterval(nextPlan?.interval)} {nextPlan?.metadata?.adFree && 'Ad-Free '}
                    {nextPlan?.currency && currencySymbol(nextPlan?.currency)}
                    {nextPlan?.prices.find((price) => price.id === nextPriceId)?.amount}
                  </span>{' '}
                  is pending and will be effective on {subscription.endDate}
                  {pm && <>, to be charged with {pm.brand}</>}
                  {pm && pm.last4 && <> *{pm.last4}</>}
                </div>
              </div>
            </>
          )}
          {state === 'inactive' && (
            <>
              <div className='subscription__info-content'>
                <div className='subscription__type member'>No active subscription</div>
                <div className='subscription__renewal'>
                  {subscription.cadence} plan ended {subscription.endDate}
                </div>
              </div>
            </>
          )}
          {state === 'pending-cancellation' && (
            <>
              <div className='subscription__info-content'>
                <div className='subscription__subscriber-label'>Canceled</div>
                <div className='subscription__type member'>{subscription.cadence} Subscription</div>
                <div className='subscription__account-details'>
                  <div className='subscription__renewal'>
                    <span className='headline-bold'>
                      Your Business Insider subscription has been canceled
                    </span>{' '}
                    and you won&apos;t be billed again. You&apos;ll still have full subscriber
                    access to Business Insider until the end of your current billing cycle on{' '}
                    {subscription.endDate}
                  </div>
                </div>
                {!isMigratedPianoUser && <RestoreSubscriptionButton />}
              </div>
            </>
          )}
          {state === 'canceled' && (
            <>
              <div className='subscription__info-content'>
                <div className='subscription__subscriber-label subscription__subscriber-label-canceled'>
                  Expired
                </div>
                <div className='subscription__type member'>No active subscription</div>
                <div className='subscription__renewal'>You have no active subscription</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConsumerStatus;
